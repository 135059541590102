import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardTitleComponent } from './card-title.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [CardComponent, CardTitleComponent],
  imports: [CommonModule, MatExpansionModule],
  exports: [CardComponent, CardTitleComponent]
})
export class CardModule {}
