import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../app.state';

export const selectLoginState = (state: AppState) => state.login;

export const selectToken = createSelector(selectLoginState, state => state.token);
export const selectLoginLoadingStatus = createSelector(selectLoginState, state => state.loginStatus);
export const selectSignupLoadingStatus = createSelector(selectLoginState, state => state.signupStatus);
export const selectSignupIsLoading = pipe(
  select(selectSignupLoadingStatus),
  map(status => status === 'loading')
);
export const selectIsLoggedIn = createSelector(selectLoginLoadingStatus, status => status === 'success');
export const selectWizefiId = createSelector(selectLoginState, state => state.id);
export const selectScreenState = createSelector(selectLoginState, state => state.screen);
