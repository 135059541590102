import { Account } from '@wizefi/entities';
import { createSelector } from '@ngrx/store';
import { AccountSelectors } from '../account/account.selectors';
import { selectInstitutions } from './institution.root.selectors';

export const selectManualInstitution = createSelector(selectInstitutions, institutions => institutions.find(i => i.isManual === true));

export const selectInstitutionOfAccount = (account: Account) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createSelector(selectInstitutions, institutions => institutions.find(i => i.itemId === account.itemId)!);

export const selectInstitutionByName = (institutionName: string) =>
  createSelector(selectInstitutions, institutions => institutions.find(i => i.institutionName === institutionName));

export const selectInstitutionByAccountId = (id: string | undefined) =>
  createSelector(selectInstitutions, AccountSelectors.selectAccountWithId(id), (institutions, account) =>
    institutions.find(i => i.itemId === account?.itemId)
  );

export const selectInstitutionLogo = (itemId: string) =>
  createSelector(selectInstitutions, institutions => institutions.find(i => i.itemId === itemId)?.logo);
