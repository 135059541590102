"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.institutionSchema = void 0;
const zod_1 = require("zod");
exports.institutionSchema = zod_1.z.object({
    itemId: zod_1.z.string(),
    accessToken: zod_1.z.optional(zod_1.z.string()),
    institutionId: zod_1.z.string(),
    institutionName: zod_1.z.string(),
    error: zod_1.z.optional(zod_1.z.string()),
    isManual: zod_1.z.boolean(),
    isActive: zod_1.z.boolean(),
    canBeUpdated: zod_1.z.optional(zod_1.z.boolean()),
    hasNeedSync: zod_1.z.optional(zod_1.z.boolean()),
    isExpanded: zod_1.z.optional(zod_1.z.boolean()),
    logoLoaded: zod_1.z.optional(zod_1.z.boolean()),
    logo: zod_1.z.optional(zod_1.z.string()),
    orderDragAndDrop: zod_1.z.optional(zod_1.z.number())
});
